module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-thepuzzlers-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/vercel/path0/src/intl","languages":["en","de"],"locales":{"default":"de","translations":[{"pathPrefix":"en","hreflang":"en"}]},"translatedPaths":[{"default":"services","de":"leistungen"},{"default":"process","de":"prozess"},{"default":"projects","de":"projekte"},{"default":"privacy-policy","de":"datenschutz"},{"default":"legal","de":"impressum"}],"secondPath":null,"redirect":false,"wrapProvider":true},
    }]
